
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
}

html, body {
    padding: 0;
    margin: 0;
    font-family: "PT Sans";
    color: #2E3640;
    height: 100%;
}

a, a:active, a:hover, a:visited {
    color: #2E3640;
    text-decoration: none;
}
.css-2b097c-container{
    width: 200px;
    color: #333;
    font-size: 12px;
}
#root, .App{
    height: 100%!important;
}

.cell-wrapper {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.step-data{
    background: #fff;
    width: 400px;
    color: #333;
    word-wrap: anywhere;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
}
.cell-wrapper__caption {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.cell-wrapper__control{
    margin-left: 20px;
    display: flex;
}
.modal-body textarea{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}
.cell-wrapper__data {
    display: grid;
    height: calc(100% - 25px);
    grid-template-columns: repeat(auto-fill, 19%);
    grid-auto-rows: 50% 50%;
    color: #fff;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.engineer .cell-wrapper__data {
    display: grid;
    height: calc(100% - 25px);
    grid-template-columns: repeat(auto-fill, 24%);
    grid-auto-rows: 100%;
    color: #fff;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.user .cell-wrapper__data {
    display: grid;
    height: calc(100% - 25px);
    grid-template-columns: repeat(auto-fill, 31%);
    grid-auto-rows: 100%;
    color: #fff;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.report-item-container>.report-item>.report-item-header{
    color: #e5ff53
}

.report-item-header {
    margin-bottom: 5px;
    font-weight: bold;
    position: relative;
}

.report-item-container {
    height: calc(100% - 35px);
    overflow: auto;
}

.report-sub-item {
    position: relative;
    padding-bottom: 5px;
    border-bottom: 1px solid #2E3640;
}

.report-sub-item a {
    color: #fff;
    width: calc(100% - 70px);
    display: block;
    overflow: hidden;
}

 .report-item-header .leads-count {
    position: absolute;
    top: 0;
    right: 25px;
    background: #fff;
    padding: 1px 3px;
    margin: 1px;
    border-radius: 3px;
    color: #333;
}
.report-sub-item .day-left, .report-sub-item .lead-id{
    margin: 0 2px;
    background: #fff;
    padding: 1px 3px;
    border-radius: 3px;
    color: #333;
}

.report-sub-item.dock a {
    color: #c8a0ff;
}
.report-sub-item .lead-id{
    background: #daf6df;
}

.sub-item-info{
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
}

.report-item-header .leads-count {
    font-weight: 100;
    right: 0;
    background: #68fb65;
}


.next-step {
    cursor: pointer;
}

.report-sub-item .next-step {
    margin: 1px;
    width: 20px;
    height: 20px;
    background-image: url('image/arrow.svg')!important;
    background-size: 50%!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
}

.report-sub-item .next-step-icon {
    background: #f39554;
    border-radius: 50%;
    color: #fff;
    padding: 0px 4px 0 6px;
}

.no-step{
    background: red!important;
}


.report-sub-item .next-step-text {
    display: none;
}

.report-sub-item .next-step:hover .next-step-text {
    display: block;
}

.day-left.day-more {
    background: red;
}

.day-left.day-less {
    background: #ff9144;
}

.cell-wrapper__data__num {
    font-size: 40px;
    line-height: 68px;
    font-family: "Open Sans", sans-serif;
}

.cell-wrapper__bottom {
    margin-top: 15px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
}

.cell-wrapper__bottom.red,
.cell-wrapper__bottom.red a {
    color: #fe6e6e;
}

.cell-wrapper__bottom.green,
.cell-wrapper__bottom.green a {
    color: #41cfc4;
}

.cell-wrapper__bottom:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 14px;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 3px;
}

.cell-wrapper__bottom.green:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCA4IDEzIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KCiAgICAgIC5jbHMtMiB7CiAgICAgICAgZmlsbDogIzM4Y2RjMTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggZD0iTTguNTAyLDQuMjc5IEM4LjUwMiw0LjI3OSA3LjYwNiw1LjIwNyA3LjYwNiw1LjIwNyBDNy42MDYsNS4yMDcgNS4wMDcsMi41MTYgNS4wMDcsMi41MTYgQzUuMDA3LDIuNTE2IDUuMDA3LDEzLjAyNiA1LjAwNywxMy4wMjYgQzUuMDA3LDEzLjAyNiAzLjQ5MywxMy4wMjYgMy40OTMsMTMuMDI2IEMzLjQ5MywxMy4wMjYgMy40OTMsMi41MTYgMy40OTMsMi41MTYgQzMuNDkzLDIuNTE2IDAuODk0LDUuMjA3IDAuODk0LDUuMjA3IEMwLjg5NCw1LjIwNyAtMC4wMDIsNC4yNzkgLTAuMDAyLDQuMjc5IEMtMC4wMDIsNC4yNzkgNC4xMTksMC4wMTIgNC4xMTksMC4wMTIgQzQuMTE5LDAuMDEyIDQuMjUwLDAuMTQ4IDQuMjUwLDAuMTQ4IEM0LjI1MCwwLjE0OCA0LjM4MSwwLjAxMiA0LjM4MSwwLjAxMiBDNC4zODEsMC4wMTIgOC41MDIsNC4yNzkgOC41MDIsNC4yNzkgWiIgaWQ9InBhdGgtMSIgY2xhc3M9ImNscy0yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==) no-repeat center center;
}

.cell-wrapper__bottom.red:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjkiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCA5IDEzIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KCiAgICAgIC5jbHMtMiB7CiAgICAgICAgZmlsbDogI2ZlNmU2ZTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggZD0iTTguNTAyLDguNzM1IEM4LjUwMiw4LjczNSA0LjM4MSwxMy4wMDIgNC4zODEsMTMuMDAyIEM0LjM4MSwxMy4wMDIgNC4yNTAsMTIuODY2IDQuMjUwLDEyLjg2NiBDNC4yNTAsMTIuODY2IDQuMTE5LDEzLjAwMiA0LjExOSwxMy4wMDIgQzQuMTE5LDEzLjAwMiAtMC4wMDIsOC43MzUgLTAuMDAyLDguNzM1IEMtMC4wMDIsOC43MzUgMC44OTQsNy44MDcgMC44OTQsNy44MDcgQzAuODk0LDcuODA3IDMuNDkzLDEwLjQ5OCAzLjQ5MywxMC40OTggQzMuNDkzLDEwLjQ5OCAzLjQ5MywtMC4wMTIgMy40OTMsLTAuMDEyIEMzLjQ5MywtMC4wMTIgNS4wMDcsLTAuMDEyIDUuMDA3LC0wLjAxMiBDNS4wMDcsLTAuMDEyIDUuMDA3LDEwLjQ5OCA1LjAwNywxMC40OTggQzUuMDA3LDEwLjQ5OCA3LjYwNiw3LjgwNyA3LjYwNiw3LjgwNyBDNy42MDYsNy44MDcgOC41MDIsOC43MzUgOC41MDIsOC43MzUgWiIgaWQ9InBhdGgtMSIgY2xhc3M9ImNscy0yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==) no-repeat center center;
}

.cell-wrapper__data,
.cell-wrapper__bottom {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.report-item-container::-webkit-scrollbar {
    height: 10px;
    color: #fff;
    overflow: visible;
    width: 10px;
}

.report-item-container::-webkit-scrollbar-button {
    color: #fff;
    height: 0;
    width: 0;
}

.report-item-container::-webkit-scrollbar-thumb {
    color: #fff;
    border-style: solid;
    border-color: #fff;
    border-width: 4px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: none;
}

.report-item-container::-webkit-scrollbar-thumb {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
    background-clip: padding-box;
    border: solid #fff;
    border-width: 1px;
    min-height: 28px;
    padding: 100px 0 0;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
}

.report-item-container::-webkit-scrollbar-track {
    color: #fff;
    box-shadow: none;
    margin: 0 4px;
}

.report-item-container::-webkit-scrollbar-track {
    color: #fff;
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px;
}

.button-input {
    padding: 5px;
    background: #4c8bf7;
    border: 0;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
}
.button-input.overdue{
    background: red;
}
.button-input:hover {
    opacity: 0.8;
}

.button-input.green {
    background: #28a745;
}
.report-item-core{
    height: 100%;
}
.report-item-core .report-item {
    height: 50%;
}
.report-item-container.engineer .report-item{
    height: 33.3333%;
}

.report-item-container.user .report-item{
    height: 20%;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal.show {
    display: block;
}

.modal {
    overflow: auto !important;
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    max-width: 500px;
    margin: 0 auto;
}

@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.modal-title {
    margin: 0;
    line-height: 1.6;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.close {
    float: right;
    font-size: 1.35rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    line-height: 1.6;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
button{
    cursor: pointer;
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    margin-right: 5px;
}
.btn-primary {
    color: #fff;
    background-color: #3490dc;
    border-color: #3490dc;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal-backdrop.show {
    opacity: 0.5;
    display:block;
}
.modal-backdrop{
    display: none;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

